@import '../include-media.scss';
$breakpoints: (small: 330px, medium: 867px, large: 1024px);

@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/Ubuntu/Ubuntu-R.ttf');
}

@font-face {
  font-family: 'Ubuntu-Light';
  src: url('../fonts/Ubuntu/Ubuntu-L.ttf');
}

@font-face {
  font-family: 'Ubuntu-Semibold';
  src: url('../fonts/Ubuntu/Ubuntu-B.ttf');
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1
}

.donate-container {
  margin-left: 40px;
  height: 87px;
  width: 345px;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 2px;
  background-color: #FFFFFF;

  &-total {
    padding: 15px;
    width: 50%;

    h1 {
      margin: auto;
      color: #000;
      font-family: "Ubuntu-Light";
      font-size: 32px;
      font-weight: 300;
    }

    p {
      margin: 5px auto;
      height: 18px;
      color: #54565B;
      font-family: 'Ubuntu';
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 18px;
    }
  }

  &-donors {
    padding: 15px;
    width: 50%;

    h1 {
      margin: auto;
      color: #5A5A5A;
      font-family: "Ubuntu";
      font-size: 32px;
      font-weight: 300;
    }

    p {
      margin: 5px auto;
      height: 18px;
      color: #54565B;
      font-family: 'Ubuntu';
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 18px;
    }
  }
}

@include media("<medium") {

  .donate-container {
    margin: 20px auto 20px auto;
  }

}

@include media("<small") {

  .donate-container {
    width: 100%;
  }

}