@import '../include-media.scss';
$breakpoints: (
	small: 330px,
	medium: 867px,
	large: 1024px,
);

@font-face {
	font-family: 'Ubuntu-Light';
	src: url('../fonts/Ubuntu/Ubuntu-L.ttf');
}

@font-face {
	font-family: 'Ubuntu-Regular';
	src: url('../fonts/Ubuntu/Ubuntu-R.ttf');
}

@font-face {
	font-family: 'Ubuntu-Semibold';
	src: url('../fonts/Ubuntu/Ubuntu-B.ttf');
}

.results-container {
	margin: auto;
	position: absolute;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 200px;
	background: white;
	overflow-y: scroll;
	width: 316px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.result-items {
	display: flex;
	width: 316px;
	height: 56px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	&-text {
		padding: 10px;
		margin: auto;
		width: 100%;
		color: rgba(0, 0, 0, 0.54);
		font-family: 'Ubuntu-Regular';
		font-size: 16px;
		letter-spacing: 0;
		background: white;
		vertical-align: middle;
	}
}

.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.column {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex: 1;
}

.Modal {
	margin: auto;
	height: 50%;
	width: 50%;
	overflow: auto;
	background: white;
}

.Overlay {
	position: fixed;

	top: 0;
	bottom: 0;
	right: 0;
	left: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgba(0, 0, 0, 0.4);
}

.subhead {
	height: 16px;
	color: #002856;
	font-family: 'Ubuntu-Semibold';
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 16px;
	margin-top: 16px;
	margin-left: 5px;
	margin-bottom: 8px;
}

.subhead-center {
	flex-direction: row;
	height: 16px;
	width: 90%;
	color: #002856;
	font-family: 'Ubuntu-Semibold';
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 16px;
	margin-top: 16px;
	margin-left: 5px;
	margin-bottom: 8px;
	text-align: center;
}

.max-donation {
	margin-top: 5px;
	margin-left: 5px;
	height: 18px;
	color: #54565b;
	font-family: 'Ubuntu-Light';
	font-size: 12px;
	letter-spacing: 0;
	line-height: 18px;
}

.terms {
	input {
		width: 13px;
		height: 13px;
		top: -1px;
		*overflow: hidden;
		margin-left: 20px;
	}

	h5 {
		text-indent: -15px;
		color: rgba(0, 0, 0, 0.54);
		font-family: 'Ubuntu-Light';
		font-size: 12px;
		font-weight: 600;
		letter-spacing: 0.5px;
	}
}

.donation-container {
	position: relative;
	margin-top: 20px;
	margin-left: 40px;
	height: 280px;
	width: 345px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	background-color: #ffffff;

	&-help {
		padding: 15px 15px 15px 15px;

		p {
			margin: auto auto auto 5px;
			height: 18px;
			width: 118px;
			color: #54565b;
			font-family: 'Ubuntu-Regular';
			font-size: 12px;
			letter-spacing: 0.5px;
			line-height: 18px;
		}
	}

	select {
		margin-top: 10px;
		text-indent: 5px;
		background-color: transparent;
		border: 1px solid rgba(0, 0, 0, 0.1);
		width: 101%;
		height: 56px;
		color: rgba(0, 0, 0, 0.54);
		font-family: 'Ubuntu-Regular';
		font-size: 16px;
		letter-spacing: 0;
		line-height: 22px;
	}

	.currencyInput {
		margin-top: 10px;
		text-indent: 10px;
		width: 100%;
		height: 56px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		color: rgba(0, 0, 0, 0.54);
		font-family: 'Ubuntu-Regular';
		font-size: 16px;
		border-radius: 4px;
		letter-spacing: 0;
		line-height: 22px;
	}

	.search {
		margin-top: 10px;
		text-indent: 10px;
		width: 100%;
		height: 56px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		color: rgba(0, 0, 0, 0.54);
		font-family: 'Ubuntu-Regular';
		font-size: 16px;
		border-radius: 4px;
		letter-spacing: 0;
		line-height: 22px;
	}

	button {
		margin-top: 10px;
		margin-bottom: 5px;
		height: 56px;
		width: 100%;
		border-radius: 8px;
		background-color: #5A5A5A;
		color: white;
		font-family: 'Ubuntu-Semibold';
		font-size: 16px;
		border: none;
	}
}

@include media('<medium') {
	.Modal {
		width: 80%;
		height: 80%;
	}

	.donation-container {
		margin: 20px auto 20px auto;
	}
}

@include media('<large') {
	.Modal {
		width: 60%;
		height: 60%;
	}
}

@include media('<small') {
	.donation-container {
		width: 100%;
	}

	.Modal {
		width: 90%;
		height: 90%;
	}

	.donation-container {
		margin: 10px auto 10px auto;
	}
}